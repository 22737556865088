import React from "react"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import * as styles from "./SocialButton.module.css"

export default function SocialButton(props) {

	const iconWidth = 32
	const iconHeight = 32

	return (
		<OutboundLink
			href={props.url}
			className={`${styles.buttonGradient} block text-center text-white text-lg py-2 px-3 rounded`}
		>
			<img
				className="inline-block my-1 mr-4"
				src={props.icon}
				alt={props.alt}
				width={iconWidth}
				height={iconHeight}
			/>
			<span className="align-middle">{props.text}</span>
		</OutboundLink >
	)
}
