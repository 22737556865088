import React from "react"
import Layout from "../components/Layout"
import VideoCard from "../components/VideoCard"
import { graphql } from "gatsby"

import { Helmet } from "react-helmet"
import useSiteMetadata from "../hooks/useSiteMetadata"

import { getImage } from "gatsby-plugin-image"

export default function NotFound({ data }) {
  const { title } = useSiteMetadata()

  return (
    <Layout>
      <Helmet>
        <title>{"File not found! - " + title}</title>
      </Helmet>
      <div className="bg-red-200 mb-8 p-8 text-center">
        <h1 className="text-3xl font-bold">File not found!</h1>
        <p className="mt-3">Perhaps you were looking for some of these?</p>
      </div>

      <main className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <VideoCard
            key={node.id}
            title={node.frontmatter.title}
            path={node.fields.slug}
            image={getImage(node.fields.defaultImage)}
			duration = {node.fields.duration}
			views = {node.fields.views}
          />
        ))}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
			duration
			views
            defaultImage {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 320
                  height: 180
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            slug
          }
          excerpt
        }
      }
    }
  }
`
