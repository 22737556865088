import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "../hooks/useSiteMetadata"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import AbbreviateNumber from "../components/AbbreviateNumber"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import * as styles from "./Video.module.css"

export default function Video({ data }) {
  const { title, twitterUsername, siteUrl } = useSiteMetadata()
  const post = data.markdownRemark

  const defaultImageURL = siteUrl + post.fields.defaultImage.publicURL

  const embedUrl = `https://www.xvideos.com/embedframe/${post.frontmatter.xvideosID}`

  const durationISO = "PT" + post.fields.duration.replace(":", "M") + "S"

  const pageTitle = post.frontmatter.title + " - " + title

  const jsonLD = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    name: post.frontmatter.title,
    description: post.frontmatter.description
      ? post.frontmatter.description
      : post.excerpt,
    thumbnailUrl: defaultImageURL,
    uploadDate: post.frontmatter.date,
    embedUrl: embedUrl,
    duration: durationISO,
    interactionStatistic: {
      "@type": "InteractionCounter",
      interactionType: { "@type": "http://schema.org/WatchAction" },
      userInteractionCount: post.fields.views,
    },
  }

  return (
    <Layout>
      <Helmet>
        <title>{pageTitle}</title>
        <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
        <meta name="description" content={jsonLD.description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={twitterUsername} />
        <meta name="twitter:creator" content={twitterUsername} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:image" content={defaultImageURL} />
        <meta property="og:description" content={jsonLD.description} />
      </Helmet>
      <div>
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            title={post.frontmatter.title}
            src={embedUrl}
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
        <div className="flex mt-4 mb-8 border-b-2">
          <h3 className="flex-1 text-xl md:text-3xl font-medium ">
            {post.frontmatter.title}
          </h3>
          <p className="text-xl md:text-3xl">
            <svg
              className="inline-block h-10 w-10"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 2 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            <AbbreviateNumber number={post.fields.views} />
          </p>
        </div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        {post.frontmatter.software === "Fallen Doll" && (
          <div className={styles.description}>
            <OutboundLink href="https://www.patreon.com/ProjectH">
              Footage captured from the game Fallen Doll
            </OutboundLink>
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(format: PLAIN, pruneLength: 2000)
      fields {
        duration
        views
        defaultImage {
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      frontmatter {
        xvideosID
        title
        date
        software
        description
      }
    }
  }
`
