import styled from "styled-components"
import SearchBox from "./search-box"

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  .SearchInput {
    outline: none;
    border: ${({ hasFocus }) => (hasFocus ? "auto" : "none")};
    border-radius: 2px;
    color: ${({ theme }) => theme.foreground};

    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
    
	width: 100%;
	background: ${({ theme }) => theme.background};
	cursor: text;
	margin-left: -1.4em;
	padding-left: 1.6em;
  }
`