import React from "react"
import SearchEngineOptimization from "./SEO"
import SocialButton from "./SocialButton"

import "./Layout.css"

import { useStaticQuery, Link, graphql } from "gatsby"

import Search from "./search"
const searchIndices = [{ name: `Pages`, title: `Pages` }]


const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
          buildTime
        }
      }
    `
  )

  const year = parseInt(data.site.buildTime)

  return (
    <>
      <SearchEngineOptimization />
      <div className="container mx-auto shadow-lg">
        <nav className="flex flex-col md:flex-row md:items-center justify-between bg-gray-800 px-4 py-2 lg:px-6 lg:py-4 ">
          <div>
            <Link to={`/`}>
              <h1 className="gradient font-bold  text-3xl md:text-4xl">
                {data.site.siteMetadata.title}
              </h1>
            </Link>
            <h2 className="text-white text-sm md:text-base my-1 lg:my-2">
              Uncensored, High Quality, High Definition Porn videos
            </h2>
          </div>
          <div
            className="flex-grow md:pt-6 lg:pt-0 text-xl"
            id="nav-content"
          >
            <Search indices={searchIndices} />
          </div>
        </nav>
        <div className="bg-white px-4 py-8">
          <main>{children}</main>
        </div>
        <div className="bg-gray-800 text-white p-4 pb-8">
          <div className="flex items-center justify-center mb-4">
            <SocialButton
              url="https://www.xvideos.com/profiles/the3dhentaihub"
              text="3DHentaiHub on XVideos"
              icon="/x.png"
              alt="XVideos Logo"
            />
          </div>
          <div className="w-full text-center">
            <p>Copyright © 3DHentaiHub.com {year} All Rights Reserved</p>
            <p>100% Cruelty Free Porn!</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
