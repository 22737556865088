import React from "react"

import AbbreviateNumber from "./AbbreviateNumber"

import { Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"

export default function VideoCard(props) {
  return (
    <div className="bg-purple-200 rounded overflow-hidden shadow-lg">
      <Link to={props.path}>
        <div className="relative">
          <GatsbyImage image={props.image} alt={props.title} loading="eager" />
          <div className="text-white font-medium">
            <p className="absolute bottom-0 right-0 bg-black bg-opacity-30 py-1 px-2 mx-1 my-2.5 rounded-lg">
              {props.duration}
            </p>
            <p className="absolute bottom-0 left-0 bg-black bg-opacity-30 py-1 px-2 mx-1 my-2.5 rounded-lg">
              <svg
                className="inline-block h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                <path
                  fillRule="evenodd"
                  d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                  clipRule="evenodd"
                />
              </svg>
              <AbbreviateNumber number={props.views} />
            </p>
          </div>
        </div>

        <h2 className="m-2 font-semibold">{props.title}</h2>
      </Link>
    </div>
  )
}
