import React from "react"
import Layout from "../components/Layout"
import VideoCard from "../components/VideoCard"
import { graphql } from "gatsby"

import { Helmet } from "react-helmet"
import useSiteMetadata from "../hooks/useSiteMetadata"

import { getImage } from "gatsby-plugin-image"

export default function Index({ data }) {
  const { title, description, siteUrl } = useSiteMetadata()

  const siteTitle = title + " - " + description

  const jsonLD = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    name: title,
    url: siteUrl,
    sameAs: ["https://twitter.com/3DHentaiHub"],
  }

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      <main className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <VideoCard
            key={node.id}
            title={node.frontmatter.title}
            path={node.fields.slug}
            image={getImage(node.fields.defaultImage)}
			duration = {node.fields.duration}
			views = {node.fields.views}
          />
        ))}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
			duration
			views
            defaultImage {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            slug
          }
          excerpt
        }
      }
    }
  }
`
